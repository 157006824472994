.btn-wrapper {
    .btn{
        width: 160px;
        border-radius: unset;
        border-color: #ccc;
    }
}
.row-wrapper {
    border: 1px solid #ccc;
    .child-row-border-top {
        border-top: 1px solid #ccc;
    }
    .toggle-btn {
        color: #525659;
        &.up-arrow {
            img {
                transform: rotate(-90deg);
            }
        }
        img {
            height: 24px;
            width: 24px;
        }
    }
    .delete-btn {
        color: red;
        img {
            width: 25px;
            height: 25px;
        }
    }
    .route-wrapper{
        position: relative;
        .google-map-wrapper {
            position: absolute;
            height: 180px;
            width: 250px;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }
    .option-wrapper {
        display: flex;
    }
    .d-none {
        display: none;
    }
}