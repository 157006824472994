@font-face {
  font-family: "Roboto-Regular";
  src: url("./Roboto-Regular.ttf");
  src: local("Roboto-Regular"),
      url("./Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: "Roboto-Black";
  src: url("./Roboto-Black.ttf");
  src: local("Roboto-Black"),
      url("./Roboto-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./Roboto-Bold.ttf");
  src: local("Roboto-Bold"),
      url("./Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("./Roboto-Italic.ttf");
  src: local("Roboto-Italic"),
      url("./Roboto-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./Roboto-Light.ttf");
  src: local("Roboto-Light"),
      url("./Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./Roboto-Medium.ttf");
  src: local("Roboto-Medium"),
      url("./Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("./Roboto-Thin.ttf");
  src: local("Roboto-Thin"),
      url("./Roboto-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
