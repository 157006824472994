@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./Assets/Fonts/Font.scss";
@import "../node_modules/@syncfusion/ej2-base/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/tailwind.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind.css";

.peg-date-picker {
border: 1px solid #D8D8D8 !important;
.e-input-group{
  border: 0px solid #fff !important;
  border-radius: 4px !important;
  :focus :hover{
    border: 4px solid #fff !important;
    border-radius: 0px !important;
  }
  }
}

.peg-date-picker-without-border {
border: 0px solid #D8D8D8 !important;
.e-input-group{
  border: 0px solid #fff !important;
  border-radius: 0px !important;
  :focus :hover{
    border: 0px solid #fff !important;
    border-radius: 0px !important;
  }
  }
}
.date-picker-border-red{
border: 1px solid red !important;
.e-input-group{
  border: 0px solid;
  border-radius: 0px !important;
  :focus :hover{
    border: 0px solid;
    border-radius: 0px !important;
  }
  }
}
.flex{
  display: flex
}

.ml10{
  margin-left: 10px !important
}
.icon-style{
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.icon-style-md{
cursor: pointer;
height: 20px;
width: 20px;
}
.icon-style-xs{
cursor: pointer;
height: 12px;
width: 12px;
}
.icon-style-sm{
cursor: pointer;
height: 16px;
width: 16px;
}
.app-map{
width: 80vw;
height: 100vh;
}
@layer base {
    
body {
  @apply bg-light font-roboto subpixel-antialiased;
}

.btn {
  @apply px-8 py-1 shadow-sm transition ease-in-out duration-300 rounded leading-snug whitespace-nowrap text-base font-medium text-medium-contrast bg-medium hover:bg-medium-shade focus:ring-primary;
}

.btn.btn-sm {
  @apply px-2 py-1 font-normal text-sm;
}

.btn.btn-lg {
  @apply text-lg px-8 py-4;
}

.btn-primary {
  @apply text-white bg-primary hover:bg-primary-tint;
}

.btn-secondary {
  @apply text-dark-shade bg-light hover:bg-dark-tint;
}

.btn-tertiary {
  @apply text-white bg-tertiary hover:bg-tertiary-tint;
}

.btn-info {
  @apply bg-secondary text-white hover:bg-secondary-tint;
}

.btn-success {
  @apply bg-success text-white hover:bg-success-tint;
}

.btn-warning {
  @apply bg-warning text-white hover:bg-warning-tint ;
}

.btn-danger {
  @apply text-white bg-danger hover:bg-danger-tint  hover:text-danger-shade  transition ease-in-out duration-300;
}

.btn.btn-primary-outline {
  @apply whitespace-nowrap text-sm font-medium text-primary border-2 bg-transparent border-primary rounded hover:border-primary-shade hover:text-primary-shade  transition ease-in-out duration-300;
}

.btn-secondary.btn-outline {
  @apply text-primary border border-primary bg-transparent hover:bg-primary-tint hover:text-white;
}

.btn-tertiary.btn-outline {
  @apply text-black border border-tertiary bg-transparent hover:bg-tertiary-tint hover:text-white;
}

.btn-primary-link {
  @apply whitespace-nowrap text-sm font-medium text-primary border-2 border-transparent rounded hover:border-primary-tint hover:text-primary-tint px-4 py-1 transition ease-in-out duration-300;
}

.btn-disabled {
  @apply opacity-50 cursor-not-allowed border-opacity-50;
}
.btn-disabled:hover {
  @apply opacity-50 cursor-not-allowed border-opacity-50;
}
}
